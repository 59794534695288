<template>
  <v-card hover
          :class="{'noclick':noClick}"
          @click="selectItem(item)">
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="text-h4 font-weight-bold">
          <v-tooltip right>
            <template #activator="{on}">
              <span v-on="on">
                {{ item.requestNumber }}
              </span>
            </template>
            <span>Vehicle Upload Number</span>
          </v-tooltip>
        </v-list-item-title>

        <v-row>
          <v-col cols="12">
            <v-tooltip top>
              <template #activator="{on}">
                <div class="text-body-1 font-weight-bold"
                     v-on="on">
                  <v-icon left>
                    mdi-account
                  </v-icon>
                  {{ item.vehicleCapture.customerInfomation.clientName }} - {{ item.vehicleCapture.customerInfomation.fleetAccountNumber }}
                </div>
              </template>
              <span>Client Name and Number</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-list-item-subtitle class="font-weight-bold">
          Count of Vehicles to be Added: {{ item.vehiclesToBeAdded }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row>
          <v-col class="py-0">
            <v-tooltip top>
              <template #activator="{on}">
                <v-chip label
                        dark
                        tag="div"
                        :color="getStatusColor(item.status)"
                        v-on="on">
                  {{ item.status }}
                </v-chip>
              </template>
              <span>Status</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>

    <v-card-text class="pt-0">
      <v-row>
        <v-col cols="12"
               sm="3">
          <div class="text-body-1 font-weight-bold">
            Load Date and Time
          </div>
          {{ item.loadDateTime }}
        </v-col>
        <v-col cols="1"
               class="text-center">
          <v-divider vertical />
        </v-col>
        <v-col cols="12"
               sm="3">
          <div class="text-body-1 font-weight-bold">
            Submission Date and Time
          </div>
          {{ }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { VEHICLE_CAPTURE_STATUSES, STATUS_COLORS } from '@/config/constants';

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    noClick: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRefresh: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

    getStatusColor(status) {
      return STATUS_COLORS[status];
    },

    getStatus(status) {
      return VEHICLE_CAPTURE_STATUSES[status].text.toUpperCase();
    },

    selectItem(item) {
      if (!this.noClick) {
        this.$router.push({
          name: 'VehicleCaptureViewVehicleCapture',
          params: { vehicleCaptureRequestNumber: item.requestNumber },
        });
      }
    },
  },
};
</script>
